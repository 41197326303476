import React from "react";
import Header from "./header";
import Footer from "./Footer";
import { Row, Col, Image,Modal,Button  } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
 import ClientRequest from "./ClientRequest"
const Home = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      {/* ----Header---- */}
      <Header />
      {/* ----End Header---- */}

      {/* ----Body---- */}
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar-example2"
        data-bs-root-margin="0px 0px -40%"
        data-bs-smooth-scroll="true"
        class="scrollspy-example bg-body-tertiary rounded-2 "
        tabindex="0"
      >
        {/* --home-- */}
        <section id="scrollspyHome" class="pt-md-11 pt-5 text-center  background">
          <div class="container  pb-3 text-white about-main">
            <h1 class="section-about fw-bold  text-center about-title">
              Experience Growth and Innovation
            </h1>
            <p class="lead text-center about-des">
              Where growth begins with a conversation. We deliver top-notch cold
              calling services and Customer Engagement that drive your business
              forward.
            </p>
            <Button variant="primary" onClick={() => setModalShow(true)}>
            Get In Touch <Icon.ArrowRight size={25} />
      </Button>
             
          </div>
        </section>
        {/* --End About-- */}

        {/* --Company's values -- */}
        <section   class="py-4 pt-md-11">
          <div class="container">
            <p class="lead mb-6 mb-lg-8 fs-3">
              <strong>At INOVOKS,</strong> we understand the challenges of
              expanding your reach in a competitive market. That's why we're
              committed to providing personalized, results-driven solutions that
              help your business thrive. With our proven strategies and a
              friendly, professional approach, we make it easier for you to
              connect with your audience and achieve sustained growth.
            </p>
            <p class="lead mb-6 mb-lg-8 fs-4">
              Join us at INOVOKS and experience the benefits of a partner who is
              as invested in your success as you are. Let's make your growth our
              priority.
            </p>
          </div>
        </section>
        {/* --End Company's values -- */}

        {/* --Products-- */}
        <section
          id="scrollspyourService"
          class="py-4 text-light"
          style={{ backgroundColor: "#392657" }}
        >
          <div class="container">
            <h1 class="fw-700 mb-5 fs-1 pt-60 text-center">What We Provide</h1>
            <Row className="pb-5">
              <Col md={6} className="mb-5">
                <h2 className="fs-4 mb-3">
                  Customer Service and Sales Outsourcing Solutions :{" "}
                </h2>
                <h5 className="fs-3 mb-3">Customer Service: </h5>
                <ul className="fs-5 ">
                  <li>
                    <strong>24/7 Support:</strong>We provide around-the-clock
                    customer service, ensuring your clients receive prompt and
                    professional assistance whenever they need it.
                  </li>
                  <li>
                    <strong>Omnichannel Support:</strong>We handle inquiries
                    across various channels, including phone, email, chat, and
                    social media, offering a convenient and consistent customer
                    experience.{" "}
                  </li>
                  <li>
                    <strong>Technical Support:</strong>Our team can be trained
                    to address technical issues and troubleshoot problems
                    efficiently.{" "}
                  </li>
                  <li>
                    <strong>Customer Relationship Management (CRM):</strong>
                    Leverage our expertise to manage customer data effectively,
                    personalize interactions, and build stronger relationships.{" "}
                  </li>
                </ul>
              </Col>
              <Col md={6} className="text-center">
                <Image src={require("./assets/img/m1.png")} width="80%" />
              </Col>
            </Row>
            <Row className="mb-5 pt-5">
              <Col md={6} className="mb-5 text-center">
                <Image src={require("./assets/img/m2.png")} width="80%" />
              </Col>
              <Col md={6}>
                <h5 className="fs-3 mb-3">Cold Calling and Sales: </h5>
                <ul className="fs-5 ">
                  <li>
                    <strong> Lead Generation:</strong>Our experienced agents can
                    generate qualified leads through targeted cold calling
                    campaigns.{" "}
                  </li>
                  <li>
                    <strong>Appointment Setting:</strong>We schedule
                    appointments with potential customers, allowing your sales
                    team to focus on closing deals.{" "}
                  </li>
                  <li>
                    <strong>Product and Service Sales:</strong>We can handle
                    outbound sales calls to introduce your products or services
                    and convert leads into paying customers.{" "}
                  </li>
                  <li>
                    <strong>Upselling and Cross-Selling</strong>Our team can
                    identify opportunities to upsell or cross-sell your existing
                    customers, increasing revenue potential.{" "}
                  </li>
                  <li>
                    <strong>Sales Support:</strong>We provide comprehensive
                    sales support, including data entry, quote generation, and
                    follow-up calls.{" "}
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="pt-5">
              <Col md={6}>
                <h5 className="fs-3 mb-3">Benefits of Partnering with Us: </h5>
                <ul className="fs-5 ">
                  <li>
                    <strong>Accelerated Growth:</strong>Free up your internal
                    resources to focus on core business activities that drive
                    growth, like product development, marketing initiatives, and
                    strategic planning. By outsourcing customer service and
                    sales, you can dedicate more time and energy to these
                    crucial areas, leading to faster and more sustainable
                    growth.{" "}
                  </li>
                  <li>
                    <strong>Cost Savings:</strong>Reduce overhead costs
                    associated with in-house customer service and sales teams.
                  </li>
                  <li>
                    <strong>Improved Customer Satisfaction:</strong>Deliver
                    exceptional customer service through our highly trained and
                    professional agents.{" "}
                  </li>
                  <li>
                    <strong>Enhanced Sales Performance:</strong>Generate
                    qualified leads, schedule appointments, and close more
                    deals.{" "}
                  </li>
                  <li>
                    <strong>Scalability:</strong>Easily scale your customer
                    service and sales efforts up or down to meet your evolving
                    business needs.{" "}
                  </li>
                </ul>
              </Col>
              <Col md={6} className="mb-5 text-center">
                <Image src={require("./assets/img/m3.png")} width="80%" />
              </Col>
            </Row>
            <p className="fs-5 ">
              We are committed to providing customized solutions that align with
              your specific goals and industry requirements. Contact us today to
              discuss how our BPO services can elevate your customer experience
              and accelerate your sales growth!{" "}
            </p>
          </div>
        </section>
        {/* --End Products-- */}

        {/* --Company's values -- */}
        <section id="scrollspyourvalues" class="py-4 pt-md-11">
          <div class="container">
            <h1 className="fs-3 mb-3 pt-60">Company's values </h1>

            <h5 class="fs-4 mb-3">Our DNA: </h5>
            <ul className="fs-5 ">
              <li>
                <strong>Customer Fanatics:</strong>We obsess over customer
                success, ensuring their needs are not just met, but exceeded.{" "}
              </li>
              <li>
                <strong>Growth Champions: </strong>We relentlessly pursue
                growth, always seeking new horizons and opportunities.{" "}
              </li>
              <li>
                <strong>Talent Magnets:</strong>We attract and nurture the best,
                fostering a culture of continuous learning and development.{" "}
              </li>
              <li>
                <strong>Authentic Partners:</strong>Transparency and genuine
                interactions are the cornerstones of our relationships.
              </li>
              <li>
                <strong>Action Heroes:</strong>We embrace bold initiatives,
                moving fast and learning along the way.{" "}
              </li>
            </ul>
          </div>
        </section>
        {/* --End Company's values -- */}
        <section id="scrollspyProducts" class="py-4 pt-md-11">
          <div class="container">
            <h1 className="fs-3 mb-3">Mission: </h1>

            <p className="fs-5 ">
              To unlock maximum growth for our clients, partners, and employees
              through exceptional BPO solutions fueled by customer obsession,
              innovation, and a commitment to excellence.{" "}
            </p>
            <h1 className="fs-3 mb-3">Vision: </h1>
            <p className="fs-5 ">
              To refine the Market standards in the BPO industry, renowned for
              our unwavering commitment to customer obsession, innovation, and
              fostering a world-class talent pool. Through exceptional BPO
              solutions and a relentless pursuit of growth, we will redefine
              industry benchmarks and empower our clients, partners, and
              employees to achieve their full potential.{" "}
            </p>
          </div>
        </section>
        <section
          id="scrollspContect"
          class=" mt-4 text-white"
          style={{ backgroundColor: "#392657" }}
        >
          <div class="container pt-5">
            <Row>
              <Col md={8}>
                <div className="px-3 mb-5">
                  <h1 className="fs-1 fw-bold mb-3">INOVOKS</h1>
                  <h3>Experience Growth and Innovation!</h3>
                  <p className="fs-6">
                    where growth begins with a conversation. We specialize in
                    delivering top-notch cold calling services and Customer
                    Engagement that drive your business forward. Our dedicated
                    team of experts transforms leads into opportunities,
                    ensuring you reach your potential customers effectively and
                    efficiently.
                  </p>
                </div>
              </Col>

              <Col md={4}>
                <div className="px-5 content-text-center">
                  <h3 className="mb-3">Contact Us</h3>
                  <p className="fs-6">
                    <strong className="me-3">
                      {" "}
                      <Icon.PinMapFill size={20} />{" "}
                    </strong>{" "}
                    Cairo - Egypt
                  </p>
                  <p className="fs-6">
                    <strong className="me-3">
                      {" "}
                      <Icon.TelephoneOutboundFill size={20} />{" "}
                    </strong>{" "}
                    +20 122 091 1077
                  </p>
                  <p className="fs-6">
                    <strong className="me-3">
                      {" "}
                      <Icon.EnvelopeAtFill size={20} />{" "}
                    </strong>{" "}
                    <a href="mailto:info@inovoks.com" className="text-white">
                      info@inovoks.com
                    </a>
                  </p>
                </div>
              </Col>
              {/* <Col md={3}>
                <div className="mb-5">
                  <a
                    href="https://www.facebook.com/profile.php?id=61562560629490&mibextid=LQQJ4d"
                    target="_blank"
                    className="text-white ms-4"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <Icon.Facebook size={40} />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/inovoks"
                    className="text-white ms-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <Icon.Linkedin size={40} />
                  </a>
                </div>
              </Col> */}
            </Row>
          </div>
        </section>
      </div>

      {/* ----End Body---- */}

      {/* ----Footer---- */}
      <Footer />
      {/* ----End Footer---- */}


      <Modal
     show={modalShow}
     onHide={() => setModalShow(false)}
     backdrop="static"
        keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Get In Touch
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <ClientRequest/>
      </Modal.Body>
      
    </Modal>
    </>
  );
};
export default Home;
