import React, { useState } from "react";
import {
  Container,
  Card,
  Form,
  Button,
  Alert
} from "react-bootstrap";
 
const AgentForm = () => {
  const [isLoading, setLoading] = useState(false);

  const [ownerName, setOwnerName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
 

  const [status, setStatus] = useState("");

  const [validated, setValidated] = useState(false);

  const onChangePhone = (event) => {
    setPhone(event.target.value);
  };
  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  
  const onChangeOwnerName = (event) => {
    setOwnerName(event.target.value);
  };
 

  const onSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();

      let emailData = {
        to: "sales.inovoks@outlook.com",
        subject: "jop applcation",
        text:
          "Name: " +
          ownerName +
          " - " +
          " Phone: " +
          phone +
          " - " +
          " Email: " +
          email +
          " - ",
         
      };
      fetch(
        "https://inovoks-backend-sendemail.azurewebsites.net/api/sendemail?code=ovh0gScxxZzO9oI_WmpvsO8Cu_TiKyr8hopIFDt016viAzFuDAWRAg==",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        }
      )
        .then((response) => response.text())
        .then((data) => {
          setStatus("success");
           setEmail("")
          setOwnerName("")
          setPhone("") 
          setValidated(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    setLoading(false);
  };

  return (
    <div>
      <Container fluid="md mt-3">
      
        <Card className="shadow-sm p-3 mb-5 rounded border border-0">
          <Card.Body className="d-flex">
            <Form
              noValidate
              validated={validated}
              onSubmit={onSubmit}
              className="w-100"
            >
              <h1 className="mb-5 fs-3" style={{ color: "#392657" }}>
              Become an Inovoker!<br></br>
              Experience Growth, innovation and  Work-life balance
              </h1>
              {status==="success"?
              <Alert   variant="success">
                Awaiting to work with you, Will get back to you as soon as possible, Stay safe
              </Alert>:
              <>
              <Form.Group className="mb-3">
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  value={ownerName}
                  onChange={onChangeOwnerName}
                  placeholder="Enter Name"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Your Name is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  value={email}
                  onChange={onChangeEmail}
                  placeholder="Enter Email"
                  type="email"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Email is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  value={phone}
                  onChange={onChangePhone}
                  placeholder="Enter Phone"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Phone is required
                </Form.Control.Feedback>
              </Form.Group>
              
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isLoading}
              >
                {isLoading ? "Loading…" : "submit"}
              </Button>
              </>
              }
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};
export default AgentForm;
