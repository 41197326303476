
import { useRoutes } from 'react-router-dom';
 import   Home  from "./components/Home";
 
 const AppRoutes = () => {
    const routes = [
      { path: '/', element: <Home /> },
];
    return useRoutes(routes);
  };
 
export default AppRoutes;