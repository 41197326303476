import React from "react";
// import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, Image,Modal,Button } from "react-bootstrap";
 import AgentForm from"./AgentForm"
const Header = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
    <Navbar fixed="top" bg="light" data-bs-theme="light" expand="lg" id="navbar-example">
      <Container>
        <Navbar.Brand href="#home">
        <Image
                  src={require("./assets/img/logo.png")}
                  width="200"
                  alt="Logo"
                />
         </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto fs-5 ms-3">
          <Nav.Link href="#scrollspyHome">Home</Nav.Link>
          <Nav.Link href="#scrollspyourService">Our Services</Nav.Link>
          <Nav.Link href="#scrollspyourvalues">Our values</Nav.Link>
          <Nav.Link href="#scrollspContect">Contact Us</Nav.Link>
          <Button variant="primary" onClick={() => setModalShow(true)}>
          Join us
      </Button>
         </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
     <Modal
     show={modalShow}
     onHide={() => setModalShow(false)}
     backdrop="static"
        keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Become an Inovoker!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <AgentForm/>
      </Modal.Body>
      
    </Modal>
    </>
  );
};
export default Header;
